import { useAppDispatch } from '@/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { CSSProperties, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ProjectStatusTag } from '@pages/project-management/components/ProjectStatusTag';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { FetchColumnsType } from '@/components/common/table-base';
import TableFetch from '@/components/common/table-fetch';
import { PreviewMissedLabel } from '@/components/form-select/InfinityFormSelect';
import { SelectCustom } from '@/components/form-select/SelectCustom';
import { StepsProcess } from '@/components/step-process/StepsProcess';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, * as s from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMotPic from '@/hooks/useMotPic';
import useMutation from '@/hooks/useMutation';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { ROUTER_IDS } from '@/utils/constants';
import { FORMAT_DATE_JP, TYPE } from '@/utils/constants/AppConstants';
import {
  CREATE_NEW_PROJECT,
  NO_DATA_CREATE_NEW_PROJECT,
  NO_DATA_PROJECT_LIST,
  ONCE_YOU_START_ADDING_PROJECTS
} from '@/utils/constants/AuthorizationProjectManagement';
import { BATCH_REGISTRATION_PROJECT_URL, CREATE_PROJECT_URL, EDIT_PROJECT_URL, VIEW_PROJECT_URL } from '@/utils/constants/RouteContants';
import { updateProjectAssignPic } from '@/utils/services/ProjectApiService';
import { PROJECT_STATUS_ID } from '@utils/constants/project';
import { DataViewer } from '@utils/helpers/common';
import { IProcesses } from '@utils/interfaces/ProjectListInterface';

import IconAddNew from '@/assets/icons/base-filter/icon-add-new.svg';
import DefaultEmptySvg from '@/assets/icons/table/base-table-empty-with-smile-face.svg';
import UploadIcon from '@assets/icons/Upload.svg';

import TableHeader, { getTotalFiltered } from './Header';
import { DEFAULT_FILTER_FORM, TYPE_OF_TASK } from './constant';
import { IFormFilter, IQueryFilterParams, IStatus, PROCESS_TRAVEL_NAME } from './models';
import { generateFilter } from './ultis';

import './Styles.scss';

export default function ProjectList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasPermission, isExternalRole, isInternalRole, user } = useAuthorization();
  const { data: listStatus } = useFetch<IStatus[]>('/prj/status', 'GET');
  const { userMotPicOptions } = useMotPic({ showInactive: true });
  const { visaCategoriesGroupOption } = useOptionsGlobal();
  const { data: defaultFilterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.PROJECT_LIST);
  const formFilter = useForm<IFormFilter>({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);
  const { mutate: deleteProject } = useMutation(`/prj/projects`, {
    method: 'DELETE',
    bodyType: 'json',
    showToastSuccess: true,
    showToastError: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('questionnaire:actions:delete') }) ?? '',
    defaultErrorMsg: t('common:MSG_032') + t('common:error_asynchronous_data_delete')
  });

  function generateDefaultFilterForm() {
    const results = {
      form: {
        ...DEFAULT_FILTER_FORM,
        primary: {
          ...DEFAULT_FILTER_FORM.primary,
          picIds: user?.id && !isExternalRole ? [user?.id] : null
        }
      },
      query: {
        filter: generateFilter({
          keyword: '',
          picIds: user?.id && !isExternalRole ? [user?.id] : undefined,
          companyId: isExternalRole ? user?.organizationId : ''
        })
      }
    };
    if (!defaultFilterConfigs?.primary) return results;

    results.form = {
      picBy: defaultFilterConfigs?.picBy ?? TYPE_OF_TASK.ME,
      primary: {
        ...defaultFilterConfigs.primary
      }
    };
    const totalFiltered = getTotalFiltered(defaultFilterConfigs.primary);
    const isFilterPic = isExternalRole ? false : results.form.picBy !== TYPE_OF_TASK.ALL;
    const isFiltering = Boolean(isFilterPic || results.form.search || totalFiltered);
    results.query = {
      filter: generateFilter({
        keyword: '',
        isExceptStatus: !isFiltering,
        ...defaultFilterConfigs?.primary,
        companyId: isExternalRole ? user?.organizationId : defaultFilterConfigs?.primary?.companyId
      })
    };
    return results;
  }

  const updateMotPic = async (newValue: string, prjId: string, version: number) => {
    const motPicType = userMotPicOptions.find((item: any) => item.value === newValue);
    try {
      const updateData = {
        id: prjId,
        picId: motPicType?.value,
        picName: motPicType?.label,
        picEmail: motPicType?.email,
        version
      };
      await updateProjectAssignPic(prjId, updateData);
      setFilter({
        ...filter
      });
    } catch (error) {}
  };

  const handleConfirmChangePic = async (newValue: string) => {
    const motPicType = userMotPicOptions.find((item: any) => item.value === newValue);
    const response = await showBasePopup({
      type: 'confirm',
      title: t(`common:MSG_P_009:title`) || '',
      msg: t('common:MSG_P_009:content', { pic: motPicType?.label }) ?? ''
    });
    if (response === 'confirm') return newValue;
    else throw new Error('cancel');
  };

  const getEmptyDataAlert = (): React.ReactNode => {
    return (
      <div className='flex flex-col items-center'>
        <span className='mt-[40px]'>
          <DefaultEmptySvg width={60} height={60} />
        </span>
        {hasPermission(CREATE_NEW_PROJECT, s.V) && (
          <p className='text-16 font-semibold text-[#12212E] my-1'>{t('project:list:create_new_project')}</p>
        )}
        {hasPermission(ONCE_YOU_START_ADDING_PROJECTS, s.V) && (
          <p className='text-base font-[400] text-[#525A68] mb-3'>{t('project:list:empty_project_table_description')}</p>
        )}
        {isInternalRole && (
          <BaseButton
            size='medium'
            icon={<IconAddNew style={{ verticalAlign: 'sub' }} />}
            onClick={() => {
              navigate(CREATE_PROJECT_URL);
            }}
            disabled={!hasPermission(NO_DATA_CREATE_NEW_PROJECT, s.C)}
          >
            {t('project:list:add_new_btn_label')}
          </BaseButton>
        )}
      </div>
    );
  };
  const columns: FetchColumnsType = [
    {
      title: t('project:fields:project_id'),
      key: 'code',
      width: 232,
      className: 'max-w-[150px]',
      fixed: 'left',
      render: (_value: any, record: any) => {
        const code = () => {
          if (!record?.isPublished) {
            return (
              <span>
                {!record?.isDraft && isInternalRole && <span className='text-negative font-normal'>{t('announcement:list:label:unpublished')} </span>}
                {!record.id ? record.code : <span className='underline'>{record.code}</span>}
              </span>
            );
          }
          if (!record.id) return record.code;
          return <span className='underline'>{record.code}</span>;
        };

        const isDoneAllTask =
          !!record.processes?.length &&
          !record.processes.some((process: IProcesses) => process.name !== PROCESS_TRAVEL_NAME && !process.completedDate);
        const showStatusTag =
          !record.isDraft &&
          ([PROJECT_STATUS_ID.NOT_STARTED, PROJECT_STATUS_ID.COMPLETED, PROJECT_STATUS_ID.CANCELLED].includes(record.statusId) || isDoneAllTask);

        return (
          <div className='flex items-center gap-[8px]'>
            <AppTooltip title={record.id ? record.code : t('project:list:project_not_public')}>
              {!record.id ? (
                <div className='block truncate text-[14px]'>
                  {record?.isDraft ? <span className='text-negative text-[14px]'>{t('basic_information:isDraft')}</span> : ''}
                  <span className='text-[14px]'>{code()}</span>
                </div>
              ) : (
                <Link to={record?.isDraft ? EDIT_PROJECT_URL(record.id) : VIEW_PROJECT_URL(record.id)} style={{ display: 'inline' }}>
                  <div className='inline-block truncate text-[14px]'>
                    {record?.isDraft ? <span className='text-negative text-[14px]'>{t('basic_information:isDraft')}</span> : ''}
                    <span className='text-[14px]'>{code()}</span>
                  </div>
                </Link>
              )}
            </AppTooltip>
            {showStatusTag && <ProjectStatusTag statusId={isDoneAllTask ? PROJECT_STATUS_ID.COMPLETED : record.statusId} />}
          </div>
        );
      }
    },
    {
      title: t('project:fields:applicant_name'),
      fixed: 'left',
      dataIndex: 'applicantName',
      width: 180,
      render(_value: any, record: any, index: number) {
        const renderName = (isToolTip: boolean = false) =>
          record.applicantName
            ?.split('、')
            ?.map((name: string, index: number) => (
              <div key={`${name}-${index}`}>
                {name && <span className={classNames('block w-fit max-w-full', !isToolTip && 'truncate')}>{name}</span>}
              </div>
            ));
        return (
          <AppTooltip className='block max-w-full w-fit  text-[14px]' title={renderName(true)}>
            {renderName()}
          </AppTooltip>
        );
      }
    },
    {
      title: t('project:fields:company_name'),
      dataIndex: 'applicantCompanyName',
      width: 140,
      render(_value: any, record: any) {
        const maxLines = record.applicantName?.split('、')?.length ?? 1;
        const style: CSSProperties =
          maxLines > 1
            ? {
                whiteSpace: 'pre-line',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: maxLines
              }
            : {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              };
        return (
          <AppTooltip title={record.applicantCompanyName}>
            <div className='max-w-full w-fit overflow-hidden' style={style}>
              {record.applicantCompanyName}
            </div>
          </AppTooltip>
        );
      }
    },
    {
      title: t('project:fields:country_of_travel'),
      dataIndex: 'applicantCountryName',
      key: 'country_of_travel',
      render(_value: any, record: any, index: number) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={record.applicantCountryName}>
            {record.applicantCountryName}
          </AppTooltip>
        );
      },
      width: 140
    },
    {
      title: t('project:fields:visa_category'),
      dataIndex: 'visaCategoryName',
      render(_value: any, record: any, index: number) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={record.visaCategoryName}>
            {record.visaCategoryName}
          </AppTooltip>
        );
      },
      width: 110
    },
    {
      title: t('project:fields:destination_base_name'),
      dataIndex: 'assignmentCompanyName',
      key: 'destination_base_name',
      width: 160,
      render(_value: any, record: any) {
        const maxLines = record.applicantName?.split('、')?.length ?? 1;
        const style: CSSProperties =
          maxLines > 1
            ? {
                whiteSpace: 'pre-line',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: maxLines
              }
            : {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              };
        return (
          <AppTooltip title={record.assignmentCompanyName}>
            <div className='max-w-full w-fit overflow-hidden' style={style}>
              {record.assignmentCompanyName}
            </div>
          </AppTooltip>
        );
      }
    },
    {
      title: t('project:fields:scheduled_travel_date'),
      key: 'departureDate',
      dataIndex: 'departureDate',
      render: (_value: any, record: any, index: number) => (
        <AppTooltip className='text-[14px]' title={record.departureDate && dayjs(record.departureDate).format(FORMAT_DATE_JP)}>
          {record.departureDate && dayjs(record.departureDate).format(FORMAT_DATE_JP)}
        </AppTooltip>
      ),
      sorter: true,
      width: 140,
      sortOrder: 'ascend'
    },
    {
      title: t('project:fields:mot_pic'),
      dataIndex: 'picId',
      key: 'picId',
      render(value: string, prj: any) {
        const label = prj?.picName ? (prj?.picStatus ? prj.picName : `(${t('account_list:status_account:inactive')})${prj.picName}`) : '';
        return isExternalRole ? (
          DataViewer.display(label)
        ) : (
          <div className='mot-pick relative'>
            <PreviewMissedLabel options={userMotPicOptions} missedValues={[value]}>
              <SelectCustom
                className='w-full'
                options={userMotPicOptions.filter((item) => item.status)}
                allowClear={false}
                preChange={handleConfirmChangePic}
                onChange={(value: any) => {
                  updateMotPic(value, prj.id, prj.version);
                }}
                value={prj.picId ?? null} // cover value undefined
                key='value'
                title=''
              />
            </PreviewMissedLabel>
          </div>
        );
      },
      tooltip: (_value: any, record: any) => {
        let label = '';
        if (record?.picName) {
          label = record.picStatus ? record.picName : `(${t('account_list:status_account:inactive')})${record.picName}`;
        }
        return label;
      },
      width: 170
    },
    {
      title: t('project:fields:process'),
      dataIndex: 'process',
      render(_: any, record: any) {
        return (
          <StepsProcess
            stepData={record.processes}
            listStatus={listStatus || []}
            disabled={[PROJECT_STATUS_ID.COMPLETED, PROJECT_STATUS_ID.CANCELLED].includes((record.statusId as PROJECT_STATUS_ID) ?? '')}
            active={record.active}
          />
        );
      },
      width: 755
    }
  ];

  const updateFilter = (values: FieldValues) => {
    const previous = formFilter.getValues();
    if (!values?.primary?.picIds?.length || isExternalRole) {
      values['picBy'] = TYPE_OF_TASK.ALL;
      formFilter.setValue('picBy', TYPE_OF_TASK.ALL);
    }
    if (values?.primary?.countryIds?.length) {
      const countryIds = values.primary.countryIds;
      const visaCategoriesFilter = visaCategoriesGroupOption.filter((country) => countryIds?.includes(country.id));
      if (
        values.primary.categoryId &&
        !visaCategoriesFilter.some((category) => category.options.some((item: any) => item.value === values.primary.categoryId))
      ) {
        formFilter.setValue('primary', {
          ...previous.primary,
          categoryId: null
        });
        values.primary = {
          ...values.primary,
          categoryId: null
        };
      }
    } else {
      formFilter.setValue('primary', {
        ...previous.primary,
        categoryId: null
      });
      values.primary = {
        ...values.primary,
        categoryId: null
      };
    }
    const totalFiltered = getTotalFiltered(values.primary);
    const isFilterPic = isExternalRole ? false : values.picBy !== TYPE_OF_TASK.ALL;
    const isFiltering = Boolean(isFilterPic || values.search || totalFiltered);
    const formValues: IQueryFilterParams = {
      keyword: values?.search,
      isExceptStatus: !isFiltering,
      ...values?.primary,
      companyId: isExternalRole ? user?.organizationId : values?.primary?.companyId
    };
    setFilter({ filter: generateFilter(formValues) });
    updateFilterConfigs({
      ...values,
      primary: {
        ...values.primary
      }
    });
  };

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const idsToDelete = records.map((itemDocument) => {
      return {
        id: itemDocument.id || '',
        version: itemDocument.version || ''
      };
    });
    if (!idsToDelete.length) {
      return 0;
    }
    const result = await deleteProject({
      projects: idsToDelete
    });
    if (!result) return 0;
    const { data } = result;
    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('questionnaire:actions:delete') })
        })
      );
    }

    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };

  return (
    <div>
      <div className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
        <div className='title-24'>{t('project:list:page_title')}</div>
        <div className='flex items-center gap-[16px] ml-auto'>
          {isInternalRole && (
            <BaseButton
              type='secondary'
              size='medium'
              icon={<UploadIcon />}
              className='w-[120px]'
              onClick={() => {
                navigate(BATCH_REGISTRATION_PROJECT_URL);
              }}
            >
              {t('batch_registration:title')}
            </BaseButton>
          )}
          <BaseButton
            size='medium'
            icon={<IconAddNew style={{ verticalAlign: 'sub' }} />}
            onClick={() => {
              navigate(CREATE_PROJECT_URL);
            }}
          >
            {t('project:list:add_new_btn_label')}
          </BaseButton>
        </div>
      </div>
      <div className='page-content'>
        <div className='data-project-table'>
          <TableFetch
            tableHeader={
              <FormProvider {...formFilter}>
                <TableHeader onSubmit={updateFilter} />
              </FormProvider>
            }
            apiEndpoint='/prj/projects/search'
            defaultSort={[
              { field: 'isDraft', order: 'descend' },
              { field: 'departureDate', order: 'ascend' }
            ]}
            apiMethod='POST'
            showAddLine={false}
            columns={columns}
            filterDefault={filter}
            rowSelection={
              isExternalRole
                ? {
                    getCheckboxProps: (record) => ({
                      disabled: !record.isDraft
                    })
                  }
                : {}
            }
            scrollTable={{ x: 0, maxHeight: '100vh - 248px' }}
            showDelete={hasPermission(s.DELETE_PROJECT_LIST, s.V)}
            showDownload={false}
            handleDeleteClick={hasPermission(s.DELETE_PROJECT_LIST, s.C) ? handleDelete : undefined}
            deleteConfirmPopup={{
              type: TYPE.DISCARD,
              title: t('common:MSG_029.1_title', { item: t('basic_information:project') }) ?? '',
              msg:
                `${t('common:MSG_029.1_description', { item: t('basic_information:project') })} ${t('common:MSG_029_description2', {
                  item: t('basic_information:project')
                })}` ?? ''
            }}
            emptyDataWithoutFilter={hasPermission(NO_DATA_PROJECT_LIST, s.V) && getEmptyDataAlert()}
          />
        </div>
      </div>
    </div>
  );
}
