import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultOptionType } from 'antd/es/select';
import { Delta } from 'quill/core';
import React, { useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { FormAttachmentTable } from '@/components/form-attachment-table/FormAttachmentTable';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionMultiplePreview from '@/components/form-select/SelectionMultiplePreview';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, { C } from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { NOTE_ANNOUNCEMENT_MAX_LENGTH, TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { ANNOUNCEMENT_LIST_URL } from '@/utils/constants/RouteContants';
import { APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT, DELETE_ANNOUNCEMENT_ATTACHMENT } from '@/utils/constants/announcement';
import { groupByKey } from '@/utils/helpers/globalHelper';
import { checkIsFirstAnnouncement, getStakeholdersList } from '@/utils/services/AnnouncementApiService';

import { ButtonGroupAction } from '../components/RenderElement';
import SelectedPhrase from '../components/SelectedPhrase';
import { FormCreateAnnouncement } from './FormValidation';
import { STATUS } from './contants';
import { IFormMethodRegisterAnnouncement, PharseMasterType } from './models';

import './Add.scss';

export type IStatus = (typeof STATUS)[keyof typeof STATUS];

export interface IAttachment {
  name: string;
  blobPath: string;
  contentType: string;
  fileSize: string;
  displayOrder: number;
  requestApproval: boolean;
  status: IStatus;
  deleted: string;
}

const Add = () => {
  const navigate = useNavigate();
  const [companyNameOptions, setCompanyNameOptions] = useState<DefaultOptionType[]>([]);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const { id } = useParams();
  const dispatch = useDispatch();
  const createRef = useRef<any>(null);
  const formMethod = useForm<IFormMethodRegisterAnnouncement>({
    mode: 'all',
    resolver: yupResolver(FormCreateAnnouncement),
    defaultValues: () => getDefaultValues() || {}
  });
  const { isDirty, isSubmitSuccessful } = formMethod.formState;
  const { data: phraseMaster } = useFetch<PharseMasterType[]>(`/prj/phrases`, 'GET');
  const { mutate: registerAnnouncement } = useMutation(`prj/projects/${id}/announcements`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true
  });

  const getDefaultValues = async (): Promise<any> => {
    try {
      const { data } = await getStakeholdersList(String(id), { query: 'activeOnly' });
      let options: DefaultOptionType[] = data
        ?.filter((i: any) => i.stakeHolderStatus)
        .map((data: any) => {
          return {
            label: data.stakeHolderName,
            value: data.stakeHolderEmail,
            stakeHolderStatus: data.stakeHolderStatus
          };
        });
      setCompanyNameOptions(options);
      const organizationId = options?.map((item) => item.value as string) || [];
      return {
        organizationId
      };
    } catch (error) {}
  };

  const onBack = () => navigate(ANNOUNCEMENT_LIST_URL(id ?? ''));
  const handleRegister: SubmitHandler<any> = async (fieldValues) => {
    const response = await onSubmitAnnouncement(fieldValues);
    return response;
  };

  const getMsgFail = (template: boolean, questionnaire: boolean) => {
    if (template && questionnaire) return t('common:MSG_P_044');
    if (template) return t('common:MSG_P_042');
    if (questionnaire) return t('common:MSG_P_043');
    return t('common:MSG_P_045');
  };

  const handleSend: SubmitHandler<any> = async (fieldValues) => {
    try {
      // Call the api to check if this is the first announcement and if any conditions are missing
      const { data } = await checkIsFirstAnnouncement(String(id));

      // Show popup warning announcement first lack of conditions
      if (data.isFirstAnnouncement) {
        const showPopup = await showBasePopup({
          title: t('dialog:cancel_editing:send'),
          msg: getMsgFail(data.templateWarning, data.questionnaireWarning),
          type: TYPE_ICON.CONFIRM
        });
        if (showPopup !== TYPE_ICON.CONFIRM) return;
      }

      return await onSubmitAnnouncement(fieldValues, STATUS.PUBLIC);
    } catch (error) {}
  };
  const onSubmitAnnouncement = async (fieldValues: any, status?: string) => {
    if (registerSuccess) {
      return;
    }
    setLoading(true);
    const dataPayload = getPayloadRequest(fieldValues, status);
    const result = await registerAnnouncement(dataPayload);
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      setRegisterSuccess(true);
      setTimeout(() => {
        setLoading(false);
        navigate(ANNOUNCEMENT_LIST_URL(id ?? ''));
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_003', {
              item: t(status ? 'button:send' : dataPayload.status === STATUS.WAITING ? 'announcement:approval_request' : 'button:create')
            })
          })
        );
      }, 500);
      return;
    }
    setLoading(false);
  };
  const getPayloadRequest = (formData: any, status?: string) => {
    const usersMail = formData.organizationId.map((email: string) => ({
      userEmail: email
    }));
    const statusValue = status ?? (formData.projectAttachments?.some((item: IAttachment) => item.requestApproval) ? STATUS.WAITING : STATUS.SAVE);
    const attachmentValue = formData.projectAttachments?.map((item: IAttachment, index: number) => ({
      name: item.name,
      blobPath: item.blobPath,
      contentType: item.contentType,
      fileSize: item.fileSize,
      displayOrder: index + 1,
      requestApproval: item.requestApproval
    }));

    return {
      title: formData?.title,
      content: formData?.content,
      users: usersMail,
      status: statusValue,
      attachments: attachmentValue
    };
  };

  const convertPhasesData = () => {
    const groupedData = groupByKey(phraseMaster ?? [], 'categoryName');
    return Object.entries(groupedData || {}).map(([categoryName, data]) => ({ name: categoryName, data }));
  };
  const handleSelectPhrase = (value: string) => {
    if (createRef?.current) {
      const cursorPosition = createRef.current.selection.savedRange?.index;
      const clipboardDelta = createRef.current.clipboard.convert({ html: value });
      const delta = new Delta().retain(cursorPosition).concat(clipboardDelta);
      createRef.current.updateContents(delta, 'user');
    }
  };

  return (
    <div id='announcement-management'>
      <div className='announcement-container h-full'>
        <FormProvider {...formMethod}>
          <div className='header flex justify-between'>
            <div className='title-24 whitespace-pre-line font-bold text-24 leading-7'>{t('announcement:add:name')}</div>
            <ButtonGroupAction
              name='projectAttachments'
              loading={loading}
              onCancel={onBack}
              onSave={formMethod.handleSubmit(handleRegister)}
              onPublish={formMethod.handleSubmit(handleSend)}
            />
          </div>
          <div className='announcement-form announcement-tabs-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-3'>
            <FormInput
              required={true}
              name='title'
              labelTx='announcement:add:title:label'
              placeholderTx={String(
                t('announcement:add:title:placeholder', {
                  field: t('announcement:add:title:label')
                })
              )}
            />
            <div>
              <EditorWithCounter
                ref={createRef}
                required={true}
                name='content'
                limit={NOTE_ANNOUNCEMENT_MAX_LENGTH}
                styleCounterLabel={'text-gray-3'}
                label={t('announcement:add:content:label') ?? ''}
                placeholder={t('announcement:add:content:placeholder') ?? ''}
                editorWrapperProps={{ className: '!h-[360px]' }}
                extraElement={<SelectedPhrase pharses={convertPhasesData()} onClickItem={handleSelectPhrase} />}
              />
            </div>
            <SelectionMultiplePreview value={formMethod.watch('organizationId')} options={companyNameOptions}>
              <FormSelect
                mode={'multiple'}
                required={true}
                name='organizationId'
                label={String(t('announcement:add:target_audience:label'))}
                placeholder={String(t('placeholder:select', { field: t('announcement:add:target_audience:label') }))}
                options={companyNameOptions}
              />
            </SelectionMultiplePreview>
            <p className={`font-medium`}>{t('announcement:add:attachment')}</p>
            <FormAttachmentTable
              name='projectAttachments'
              documentType='attachments'
              allowRemove={hasPermission(DELETE_ANNOUNCEMENT_ATTACHMENT, C)}
              allowApproveReject={hasPermission(APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT, C)}
              requestApproval
            ></FormAttachmentTable>
          </div>
        </FormProvider>
      </div>
      <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
    </div>
  );
};

export default Add;
