import { useAppSelector } from '@/hooks';
import { Button, Tag } from 'antd';
import { entries, filter, isArray, isNil } from 'lodash';
import { useMemo } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBaseFilter from '@/components/base-filter';

import useAuthorization from '@/hooks/useAuthorization';
import useCompany from '@/hooks/useCompany';
import useFetch from '@/hooks/useFetch';
import useMotPic from '@/hooks/useMotPic';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';

import PrimaryFormFilter from './PrimaryFormFilter';
import { DEFAULT_FILTER_FORM, DEFAULT_FILTER_FORM_EXTERNAL, FILTER_TASK_TYPE_OPTIONS, TASK_LABEL, TYPE_OF_TASK } from './constant';
import { IFormFilter, IPrimary } from './models';

export const getTotalFiltered = (watchPrimaryFields: IPrimary): number => {
  const keyNoNeedCount = 'isDraft';
  return filter(
    entries(watchPrimaryFields),
    ([key, value]) => !isNil(value) && value !== '' && key !== keyNoNeedCount && (!isArray(value) || value.length > 0)
  ).length;
};

export interface ITableHeaderProps {
  onSubmit: SubmitHandler<IFormFilter>;
}

const TableHeader = ({ onSubmit }: ITableHeaderProps) => {
  const formFilter = useFormContext<IFormFilter>();
  const { t } = useTranslation();
  const { user, isExternalRole } = useAuthorization();
  const { countryOptions } = useOptionsGlobal();
  const countries = useAppSelector((state) => state?.global?.countries);
  const { userMotPicOptions } = useMotPic();
  const { companyOptions } = useCompany();
  const { data: statusData } = useFetch<any[]>('/prj/status', 'GET');
  const { data: processData } = useFetch<any[]>('/tmpl/processes', 'GET');
  const watchPrimaryFields = formFilter.watch('primary');
  const getVisaCategories = () => {
    let categories: any[] = [];
    countries.forEach((item) => {
      categories = categories.concat(item.categories);
    });
    return categories;
  };
  const totalFiltered = useMemo(() => getTotalFiltered(watchPrimaryFields), [watchPrimaryFields]);
  const renderTagSection = () => {
    const primaryKeys = [
      'countryIds',
      'categoryId',
      'picIds',
      'companyId',
      'process',
      'statuses',
      'departureFrom',
      'departureTo',
      'createDateFrom',
      'createDateTo'
    ];
    if (!totalFiltered) return null;
    return (
      <div className='mt-4 flex flex-wrap gap-4'>
        <div className='flex flex-wrap gap-2'>
          {primaryKeys.map((key: string) => {
            const value = watchPrimaryFields[key as keyof Omit<IPrimary, 'isDraft'>];
            let label: any = value;
            if (!value) return;
            if (key === 'countryIds') {
              return (
                <>
                  {typeof value === 'object' &&
                    value?.map((countryId) => (
                      <Tag
                        key={'countryIds' + countryId}
                        closable
                        className='bg-gray2 body-400 tag-item flex items-center'
                        onClose={() => {
                          const newData = value?.filter((item) => item !== countryId);
                          formFilter.setValue('primary', {
                            ...watchPrimaryFields,
                            countryIds: newData.length ? newData : null
                          });
                          formFilter.handleSubmit(onSubmit)();
                        }}
                      >
                        {countryOptions?.find((i) => i.value === countryId)?.label}
                      </Tag>
                    ))}
                </>
              );
            } else if (key === 'picIds') {
              return (
                <>
                  {typeof value === 'object' &&
                    value?.map((picId) => {
                      const label = userMotPicOptions?.find((i) => i.value === picId)?.label;
                      return (
                        <Tag
                          key={'picId' + picId}
                          closable
                          className='bg-gray2 body-400 tag-item flex items-center'
                          onClose={() => {
                            const newData = value?.filter((item) => item !== picId);
                            formFilter.setValue('primary', {
                              ...watchPrimaryFields,
                              picIds: newData.length ? newData : null
                            });
                            formFilter.handleSubmit(onSubmit)();
                          }}
                        >
                          {picId === '' ? t('project:filter:not_yet_assigned') : label}
                        </Tag>
                      );
                    })}
                </>
              );
            } else if (key === 'categoryId') {
              const visaCategories = getVisaCategories();
              label = visaCategories?.find((i) => i.id === value)?.code?.toUpperCase();
            } else if (key === 'companyId') {
              label = companyOptions?.find((i) => i.id === value)?.label;
            } else if (key === 'statuses') {
              if (!value) {
                return null;
              }
              const selectedStatues = Array.isArray(value) ? value : [value];
              return selectedStatues.map((statusId) => (
                <Tag
                  key={'statusId' + statusId}
                  closable
                  className='bg-gray2 body-400 tag-item flex items-center'
                  onClose={() => {
                    const newData = selectedStatues.filter((item) => item !== statusId);
                    formFilter.setValue('primary', {
                      ...watchPrimaryFields,
                      statuses: newData.length ? newData : null
                    });
                    formFilter.handleSubmit(onSubmit)();
                  }}
                >
                  {statusData?.find((i) => i.id === statusId)?.name}
                </Tag>
              ));
            } else if (key === 'process') {
              label = processData?.find((i) => i.id === value)?.name;
            }
            return (
              <Tag
                key={key}
                closable
                className='bg-gray2 body-400 tag-item flex items-center'
                onClose={() => {
                  formFilter.setValue('primary', {
                    ...watchPrimaryFields,
                    [key]: null
                  });
                  formFilter.handleSubmit(onSubmit)();
                }}
              >
                {label}
              </Tag>
            );
          })}
        </div>
        <Button
          size='small'
          className='tag-btn__clear-all'
          onClick={() => {
            formFilter.setValue('primary', isExternalRole ? DEFAULT_FILTER_FORM_EXTERNAL.primary : DEFAULT_FILTER_FORM.primary);
            formFilter.handleSubmit(onSubmit)();
          }}
        >
          {t('button:clear_filter')}
        </Button>
      </div>
    );
  };
  return (
    <div className='mb-4'>
      <FormBaseFilter
        onSubmit={(value) => {
          const { picBy } = value;
          switch (picBy) {
            case TYPE_OF_TASK.ALL:
              formFilter.setValue('picBy', TYPE_OF_TASK.ALL);
              formFilter.setValue('primary', {
                ...watchPrimaryFields,
                picIds: null,
                isDraft: null
              });
              formFilter.handleSubmit(onSubmit)();
              break;
            case TYPE_OF_TASK.ME:
              if (user?.id) {
                formFilter.setValue('picBy', TYPE_OF_TASK.ME);
                formFilter.setValue('primary', {
                  ...watchPrimaryFields,
                  picIds: [user?.id],
                  isDraft: true
                });
              }
              break;
            default:
              break;
          }
          onSubmit(formFilter.getValues());
        }}
        searchBox={{ placeholder: DataViewer.display(t('common:PROJECT_FIELDS_SEARCH_PLACEHOLDER')) }}
        tagSection={renderTagSection()}
        primaryAction={{
          label: 'button:filter',
          name: 'primary',
          popoverContent: (
            <PrimaryFormFilter
              defaultValues={formFilter.watch('primary')}
              onSubmit={(values) => {
                const previous = formFilter.getValues();
                formFilter.reset({
                  ...previous,
                  primary: {
                    ...values,
                    departureFrom: values?.departureFrom ? formatDateTime(values?.departureFrom) : null,
                    departureTo: values?.departureTo ? formatDateTime(values?.departureTo) : null,
                    createDateFrom: values?.createDateFrom ? formatDateTime(values?.createDateFrom) : null,
                    createDateTo: values?.createDateTo ? formatDateTime(values?.createDateTo) : null
                  }
                });
                formFilter.handleSubmit(onSubmit)();
              }}
              onReset={() => {}}
            />
          ),
          totalFiltered: totalFiltered || undefined
        }}
        secondaryAction={
          isExternalRole
            ? null
            : {
                options: FILTER_TASK_TYPE_OPTIONS as any[],
                name: 'picBy',
                label: TASK_LABEL[formFilter.getValues('picBy') as TYPE_OF_TASK]
              }
        }
      />
    </div>
  );
};

export default TableHeader;
