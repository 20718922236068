import i18n from '@/i18n';
import { DefaultOptionType } from 'antd/es/select';

import { IFormFilter } from '../models';

export enum TYPE_OF_TASK {
  ALL = 'allProject',
  ME = 'myProject',
  SPECIAL = 'special'
}

export const DEFAULT_FILTER_FORM: IFormFilter = {
  picBy: TYPE_OF_TASK.ME,
  primary: {
    countryIds: null,
    categoryId: null,
    picIds: null,
    companyId: null,
    process: null,
    statuses: null,
    departureFrom: null,
    departureTo: null,
    createDateFrom: null,
    createDateTo: null,
    deadlineFrom: null,
    deadlineTo: null
  }
};
export const DEFAULT_FILTER_FORM_EXTERNAL: IFormFilter = {
  primary: {
    countryIds: null,
    categoryId: null,
    process: null,
    statuses: null,
    departureFrom: null,
    departureTo: null,
    createDateFrom: null,
    createDateTo: null
  }
};
export const TASK_LABEL = {
  [TYPE_OF_TASK.ALL]: 'project:second_filter:all_project',
  [TYPE_OF_TASK.ME]: 'project:second_filter:my_project',
  [TYPE_OF_TASK.SPECIAL]: 'project:second_filter:special_project'
};

export const FILTER_TASK_TYPE_OPTIONS: DefaultOptionType[] = [
  {
    label: i18n.t('project:second_filter:my_project'),
    value: TYPE_OF_TASK.ME
  },
  {
    label: i18n.t('project:second_filter:all_project'),
    value: TYPE_OF_TASK.ALL
  }
];
