import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import QueryFactory, { IQueryItem, QueryItem } from '@/utils/helpers/filters/base';
import { convertFullWidthToHalfWidth, getEndOfDay, sinceDateBefore } from '@/utils/helpers/globalHelper';
import { PROJECT_STATUS_ID } from '@utils/constants/project';

import { IQueryFilterParams } from '../models';

export class ProjectListQueryFactory extends QueryFactory {
  getByMyProject(value: any[], field: string[]) {
    const condition = this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY;
    const newQuerySection = this.initNewQuery(condition);
    const operations = [QUERY_OPERATION.IN, QUERY_OPERATION.EQUAL];
    const conditions = [QUERY_CONDITION.EMPTY, QUERY_CONDITION.OR];
    for (let i = 0; i < field.length; i++) {
      newQuerySection.childrens?.push(
        new QueryItem(field[i], QUERY_TYPE.GUID, i === 0 ? value[i]?.join(';') || '' : value[i], operations[i], conditions[i])
      );
    }
    this.append(newQuerySection);
    return this;
  }

  getById(value: string, field: string, operation = QUERY_OPERATION.EQUAL) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem().modules.guidWith(value, { fieldTitle: field, operation });
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }

  getByIds(value: string[], field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem(field, QUERY_TYPE.GUID, value?.join(';') ?? '', QUERY_OPERATION.IN, QUERY_CONDITION.AND);
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }

  getByDateForm(date: string, fieldTitle: string, isDepartureDefault: boolean = false) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const queryItems = [new QueryItem().modules.fromDate(date, { fieldTitle })];
    if (isDepartureDefault) {
      queryItems.push(new QueryItem(fieldTitle, QUERY_TYPE.NULLABLE_DATE, '', QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR));
    }
    newQuerySection?.childrens?.push(...queryItems);
    this.append(newQuerySection);
    return this;
  }

  getByDateTo(date: string, fieldTitle: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle }));
    this.append(newQuerySection);
    return this;
  }

  getGuidQueryItem(value: string, configs: Pick<IQueryItem, 'fieldTitle' | 'operation' | 'queryCondition'>) {
    const {
      fieldTitle,
      operation = QUERY_OPERATION.EQUAL,
      queryCondition = this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY
    } = configs;
    const newQueryItem = new QueryItem().modules.guidWith(value, { fieldTitle, operation, queryCondition });
    this.append(newQueryItem);
    return this;
  }

  getStatusAndDepartureDateDefault({
    statuses = PROJECT_STATUS_ID.COMPLETED,
    departureDateFrom = ''
  }: {
    statuses?: string | string[];
    departureDateFrom: string;
  }) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    if (!newQuerySection.childrens) {
      newQuerySection.childrens = [];
    }
    const departureDateFromQueryItem = new QueryItem().modules.fromDate(departureDateFrom, {
      fieldTitle: 'DepartureDate'
    });
    const departureDateToQueryItem = new QueryItem().modules.toDate(departureDateFrom, {
      fieldTitle: 'DepartureDate'
    });
    const departureDateNullableQueryItem = new QueryItem('DepartureDate', QUERY_TYPE.NULLABLE_DATE, '', QUERY_OPERATION.EQUAL, QUERY_CONDITION.EMPTY);
    const statusQueryItems = (statuses instanceof Array ? statuses : [statuses]).map((status) =>
      new QueryItem().modules.guidWith(status, {
        fieldTitle: 'StatusId',
        operation: QUERY_OPERATION.NOT_EQUAL,
        queryCondition: QUERY_CONDITION.AND
      })
    );

    newQuerySection.childrens.push(departureDateFromQueryItem);

    const departureDateToQuerySection = new QueryItem(null, null, undefined, undefined, QUERY_CONDITION.OR, []);
    if (!departureDateToQuerySection.childrens) {
      departureDateToQuerySection.childrens = [];
    }
    departureDateToQuerySection.childrens.push(departureDateToQueryItem, ...statusQueryItems);

    const departureDateNullableQuerySection = new QueryItem(null, null, undefined, undefined, QUERY_CONDITION.OR, []);
    if (!departureDateNullableQuerySection.childrens) {
      departureDateNullableQuerySection.childrens = [];
    }
    departureDateNullableQuerySection.childrens.push(departureDateNullableQueryItem, ...statusQueryItems);

    newQuerySection.childrens.push(departureDateToQuerySection, departureDateNullableQuerySection);

    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new ProjectListQueryFactory();
  if (params?.keyword) {
    const fields = [
      'code.ToLower()',
      'codeSearch.ToLower()',
      'picName.ToLower()',
      'applicantCompanyNameSearch.ToLower()',
      'assignmentCompanyNameSearch.ToLower()',
      'applicantNameSearch.ToLower()',
      'applicantNameRomajiSearch.ToLower()',
      'applicantNameTrimSearch.ToLower()',
      'applicantNameRomajiTrimSearch.ToLower()'
    ];
    filterFactory.searchingBy(fields, convertFullWidthToHalfWidth(params.keyword).toLowerCase());
  }
  if (params?.countryIds?.length) {
    filterFactory.getByIds(params.countryIds, 'ApplicantCountryId');
  }
  if (params?.categoryId) {
    filterFactory.getById(params.categoryId, 'VisaCategoryId');
  }
  if (params?.picIds?.length && params?.isDraft) {
    filterFactory.getByMyProject([params.picIds, params.isDraft], ['PicId', 'IsDraft']);
  } else if (params?.picIds?.length) {
    filterFactory.getByIds(params.picIds, 'PicId');
  }
  if (params?.companyId) {
    params?.isExceptStatus
      ? filterFactory.getGuidQueryItem(params.companyId, { fieldTitle: 'ApplicantCompanyId' })
      : filterFactory.getById(params.companyId, 'ApplicantCompanyId');
  }
  if (params?.statuses) {
    filterFactory.getByIds(params.statuses, 'StatusId');
  } else if (params?.isExceptStatus) {
    filterFactory.getStatusAndDepartureDateDefault({
      statuses: [PROJECT_STATUS_ID.COMPLETED, PROJECT_STATUS_ID.CANCELLED],
      departureDateFrom: sinceDateBefore(30)
    });
  }
  if (params?.departureFrom) {
    filterFactory.getByDateForm(params.departureFrom, 'DepartureDate');
  } else if (params?.departureDefault) {
    filterFactory.getByDateForm(params.departureDefault, 'DepartureDate', true);
  }
  if (params?.departureTo) {
    filterFactory.getByDateTo(getEndOfDay(params.departureTo), 'DepartureDate');
  }
  if (params?.createDateFrom) {
    filterFactory.getByDateForm(params.createDateFrom, 'CreatedDate');
  }
  if (params?.createDateTo) {
    filterFactory.getByDateTo(getEndOfDay(params.createDateTo), 'CreatedDate');
  }
  return filterFactory.sanitize().stringifyData();
};
