import { useAppSelector } from '@/hooks';
import AppLayout from '@/layouts/AppLayout';
import { ReactNode, useEffect, useMemo } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, matchPath, useNavigate } from 'react-router-dom';

import LoadingPage from '@/pages/error-page/loading-page/LoadingPage';
import Callback from '@pages/callback';
import NotExits from '@pages/error-page/404-page/NotExits';

import { setLoadingPageGlobal } from '@redux/globalReducer';
import { AppDispatch } from '@redux/store';

import useAuthorization from '@hooks/useAuthorization';

import { SESSION_STORAGE } from '@/utils/constants';
import {
  BASIC_INFORMATION,
  COMMENT_CHAT_NAME,
  DOCUMENT_LIST,
  EDIT_BASIC_INFORMATION,
  EDIT_DOCUMENT_LIST,
  PROJECT_MEMO
} from '@/utils/constants/AuthorizationProjectManagement';
import { NOT_EXITS_URL, PROJECT_LIST_URL } from '@utils/constants/RouteContants';
import { RouterLoaderObserver } from '@utils/helpers/routeLoaders';
import { IRoute } from '@utils/interfaces/route';

import ROUTES from './routes';
import WithoutLayout from './withoutLayout';

const routerLoaders = (route: IRoute, auth: AuthContextProps) => {
  const loaders = new RouterLoaderObserver(route, auth);
  if (!loaders.isValid()) return;
  return loaders.watching;
};

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { error, isAuthenticated, isLoading, signinRedirect } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const userProfile = useAppSelector((x) => x.global.userProfile);
  const pathWithSearch = `${window.location.pathname}${window.location.search ?? ''}`;

  if (isLoading) {
    return <LoadingPage />;
  }

  const isLoggingOut = sessionStorage.getItem(SESSION_STORAGE.LOGGING_OUT);
  const returnUrl = sessionStorage.getItem(SESSION_STORAGE.RETURN_URL);
  if (error) {
    signinRedirect();
    if (!returnUrl && !isLoggingOut) sessionStorage.setItem(SESSION_STORAGE.RETURN_URL, window.location.href);
    dispatch(setLoadingPageGlobal(false));
    return <div>{error.message}</div>;
  }

  if (!isAuthenticated) {
    if (pathWithSearch.startsWith('/projects/edit')) {
      sessionStorage.setItem('path', pathWithSearch);
      sessionStorage.setItem('email', userProfile?.email ?? '');
    }
    signinRedirect();
    if (!returnUrl && !isLoggingOut) sessionStorage.setItem(SESSION_STORAGE.RETURN_URL, window.location.href);
    dispatch(setLoadingPageGlobal(false));
    return <></>;
  }

  return <>{children}</>;
};

export default function AppRoutes() {
  const { roles } = useAuthorization();
  const auth = useAuth();
  const loading = useAppSelector((x) => x.notice.refreshPage);

  useEffect(() => {
    const currentPage = ROUTES.filter((r: any) => r.auth).find((r: any) => matchPath(r, location.pathname));
    if (currentPage && roles.length > 0) {
      const useRoles = currentPage?.role || [];
      const currentRole = roles || '';
      if (!useRoles.includes(currentRole)) {
        window.location.href = NOT_EXITS_URL;
        return;
      }
    }
  }, [window.location.pathname, roles]);

  const ROUTE_INDEX_COMP = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate(PROJECT_LIST_URL);
    }, []);

    return null;
  };

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route>
            {WithoutLayout.filter((r: IRoute) =>
              [EDIT_BASIC_INFORMATION, BASIC_INFORMATION, EDIT_DOCUMENT_LIST, DOCUMENT_LIST, PROJECT_MEMO, COMMENT_CHAT_NAME].includes(r.name)
            ).map((r: IRoute, i: number) => {
              return <Route key={`public-route-${i + 12}`} path={r.path} element={r.element} loader={routerLoaders(r, auth)} />;
            })}
            <Route
              path='/'
              element={
                <PrivateRoute>
                  <AppLayout />
                </PrivateRoute>
              }
            >
              {ROUTES.filter((r: IRoute) => r.auth).map((r: IRoute, i: number) => {
                return (
                  <Route
                    key={`private-route-${i + 1}`}
                    path={r.path}
                    element={loading ? <LoadingPage /> : r.element}
                    loader={routerLoaders(r, auth)}
                  />
                );
              })}
              <Route index element={<ROUTE_INDEX_COMP />} />
            </Route>
            {ROUTES.filter((r: IRoute) => !r.auth).map((r: IRoute, i: number) => {
              return <Route key={`public-route-${i + 1}`} path={r.path} element={r.element} loader={routerLoaders(r, auth)} />;
            })}
            <Route path='*' element={<NotExits />} />
            <Route path='/callback' element={<Callback />} />
          </Route>
        )
      ),
    [auth.isAuthenticated, JSON.stringify(roles), loading]
  );

  return <RouterProvider router={router} />;
}
