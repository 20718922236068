import { IQueryFilterParams } from '@pages/project-management/project-list-v2/models';

import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@utils/constants';
import QueryFactory, { QueryItem } from '@utils/helpers/filters/base';
import { getEndOfDay } from '@utils/helpers/globalHelper';

class TaskListQueryFactory extends QueryFactory {
  getById(value: string, field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem().modules.guidWith(value, { fieldTitle: field });
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }

  getByIds(value: string[], field: string, queryType = QUERY_TYPE.GUID) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem(field, queryType, value?.join(';') ?? '', QUERY_OPERATION.IN, QUERY_CONDITION.AND);
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }

  getByDateFrom(date: string, fieldTitle: string, isDepartureDefault: boolean = false) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const queryItems = [new QueryItem().modules.fromDate(date, { fieldTitle })];
    if (isDepartureDefault) {
      queryItems.push(new QueryItem(fieldTitle, QUERY_TYPE.NULLABLE_DATE, '', QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR));
    }
    newQuerySection?.childrens?.push(...queryItems);
    this.append(newQuerySection);
    return this;
  }

  getByDateTo(date: string, fieldTitle: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle }));
    this.append(newQuerySection);
    return this;
  }
}

const generateFilter = (params?: IQueryFilterParams & { projectPicId?: string | null }): string => {
  const filterFactory = new TaskListQueryFactory();
  if (params?.projectPicId) {
    filterFactory.getById(params.projectPicId, 'projectPicId');
  }
  if (params?.categoryId) {
    const categoryIds = typeof params.categoryId === 'string' ? [params.categoryId] : params.categoryId;
    filterFactory.getByIds(categoryIds, 'taskType', QUERY_TYPE.TEXT);
  }
  if (params?.countryIds?.length) {
    filterFactory.getByIds(params.countryIds, 'applicantCountryId');
  }
  if (params?.deadlineFrom) {
    filterFactory.getByDateFrom(params.deadlineFrom, 'deadLine');
  }
  if (params?.deadlineTo) {
    filterFactory.getByDateTo(getEndOfDay(params.deadlineTo), 'deadLine');
  }
  if (params?.statuses) {
    filterFactory.getByIds(params.statuses, 'statusId');
  }

  return filterFactory.sanitize().stringifyData();
};

export { generateFilter };
