import i18n from '@/i18n';
import { DefaultOptionType } from 'antd/es/select';

export const DATE_FORMAT = 'yyyy/mm/dd';

// export enum STATUS {
//   TODO = '0884121d-0adb-49e9-9f31-6d9b1b3b815a',
//   IN_PROGRESS = 'a471467a-36af-4687-a786-674b85f62520',
//   PENDING = '92d583f1-ae08-4acd-a361-110972e45874',
//   COMPLETED = 'da842f61-e2ec-44fc-a761-5eab8bdd5259',
//   CANCELLED = '2ae1e710-864a-4fdc-81af-b6b9829244c4'
// }

export enum STATUS_NAME {
  TODO = '開始前',
  IN_PROGRESS = '進行中',
  DELAYED = '遅延',
  COMPLETED = '完了',
  CANCELLED = '中止'
}

export enum GENDER {
  male = 'male',
  female = 'female'
}

export enum TYPE_OF_TASK {
  ALL = 'allProject',
  ME = 'myProject',
  SPECIAL = 'special'
}

export const FILTER_TASK_TYPE_OPTIONS: DefaultOptionType[] = [
  {
    label: i18n.t('project:second_filter:my_project'),
    value: TYPE_OF_TASK.ME
  },
  {
    label: i18n.t('project:second_filter:all_project'),
    value: TYPE_OF_TASK.ALL
  }
];
export enum LANGUAGE {
  japan = 'japan',
  english = 'english'
}

export enum GO_WITH_FAMILY {
  YES = 'yes',
  NO = 'no'
}
