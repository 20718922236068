import { Button, Tag, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { SortOrder } from 'antd/es/table/interface';
import lodash, { isNil } from 'lodash';
import React, { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { STATUS_NAME, TASK_TYPE } from '@pages/project-management/detail/constant';
import { renderTaskStatus } from '@pages/project-management/detail/utils/helper';
import { DEFAULT_FILTER_FORM, TYPE_OF_TASK } from '@pages/project-management/project-list-v2/constant';
import { IFormFilter, IPrimary } from '@pages/project-management/project-list-v2/models';
import { IStatus } from '@pages/project-management/project-list/models';
import { PrimaryFilter } from '@pages/task/components';

import AppTooltip from '@components/app-tooltip/AppTooltip';
import FormBaseFilter, { RefActionControl } from '@components/base-filter';
import { FetchColumnsType, IObject } from '@components/common/table-base';
import TableFetch, { IDefaultSort } from '@components/common/table-fetch';
import { TagTaskType } from '@components/common/task/TagTaskType';

import useAuthorization from '@hooks/useAuthorization';
import useFetch from '@hooks/useFetch';
import { useFilterConfigs } from '@hooks/useFilterConfigs';
import useOptionsGlobal from '@hooks/useOptionsGlobal';

import { getProjectTaskPicInfo } from '@/utils/services/ProjectApiService';
import { ANTD_TO_QUERY_SORT, QUERY_SORT, ROUTER_IDS } from '@utils/constants';
import { API } from '@utils/constants/Apis';
import { FORMAT_DATE_EN } from '@utils/constants/AppConstants';
import { VIEW_PROJECT_URL, VIEW_TASK_OF_PROJECT_URL } from '@utils/constants/RouteContants';
import { DataViewer } from '@utils/helpers/common';
import { convertFullWidthToHalfWidth, formatDateTime } from '@utils/helpers/globalHelper';
import { IUserProfile } from '@utils/interfaces/user';

import { generateFilter } from '../utils';

type PrimaryKey = keyof Omit<IPrimary, 'isDraft'>;

export type PicInfo = IUserProfile & { label?: string };
export const TaskList = () => {
  const { t } = useTranslation();
  const { user, isInternalRole, isExternalRole } = useAuthorization();
  const { data: filterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.TASK_LIST);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [filter, setFilter] = useState({
    filter: generateFilter({
      projectPicId: isInternalRole && filterConfigs?.picBy === TYPE_OF_TASK.ME ? user?.id : null,
      ...filterConfigs?.primary,
      categoryId: isExternalRole ? TASK_TYPE.OTHER : filterConfigs?.primary?.categoryId
    }),
    textSearch: '',
    filterTaskPics: filterConfigs?.primary?.picIds?.join(';'),
    assignedTasks: isInternalRole && filterConfigs?.picBy === TYPE_OF_TASK.SPECIAL
  });
  const filterTableRef = useRef<RefActionControl>({
    toggle: () => {}
  });

  const { countryOptions } = useOptionsGlobal();
  const { data: statuses = [] } = useFetch<IStatus[]>(`${API.GET_PROJECT_STATUS_LIST}?type=task_list`, 'GET');

  const DEFAULT_FORM_FILTER_VALUES = {
    picBy: TYPE_OF_TASK.ME,
    search: '',
    primary: {
      ...DEFAULT_FILTER_FORM.primary,
      categoryId: isExternalRole ? [TASK_TYPE.OTHER] : null
    }
  };
  const methods = useForm<IFormFilter>({ defaultValues: DEFAULT_FORM_FILTER_VALUES });
  const { watch, setValue, reset, getValues } = methods;
  const [watchPicBy, watchPrimary, watchSearch] = watch(['picBy', 'primary', 'search']);
  const [picListInfo, setPicListInfo] = useState<PicInfo[]>([]);

  const fetchPicInfo = async (picIds: string[]) => {
    const responses = await getProjectTaskPicInfo(picIds);
    return (
      responses?.data?.map((res: any) => ({
        ...res,
        id: res.picId,
        value: res.picId,
        label: !res?.status ? `(${t('account_list:status_account:inactive')})${res.name}` : res.name
      })) ?? []
    );
  };

  const validateFilterPic = async (configs: FieldValues | null) => {
    try {
      const picIds = configs?.primary?.picIds;
      if (!configs || !picIds?.length) return configs;
      const newData = await fetchPicInfo(picIds);
      setPicListInfo(newData);
      return configs;
    } catch (error) {
      return configs;
    }
  };

  useEffect(() => {
    if (!filterConfigs || initialLoaded) {
      return;
    }
    (async () => {
      const sanitizeFilterConfigs = await validateFilterPic(filterConfigs);
      reset({
        ...DEFAULT_FORM_FILTER_VALUES,
        ...sanitizeFilterConfigs
      });
      setInitialLoaded(true);
    })();
  }, [filterConfigs, initialLoaded]);

  const COLUMNS: FetchColumnsType<IObject> = [
    {
      title: t('task:deadline'),
      key: 'deadLine',
      width: 130,
      sorter: true,
      sortOrder: 'ascend',
      render: (data) => DataViewer.displayTime(data.deadLine, FORMAT_DATE_EN, '')
    },
    {
      title: t('task:type'),
      key: 'taskType',
      width: 90,
      render: (data) => <TagTaskType type={data.taskType} className='m-0 w-[62px] text-center h-[24px] leading-[22px]' />
    },
    {
      title: t('task:manager'),
      key: 'taskPics',
      width: 130,
      render: (data) => {
        return (
          <div className='w-fit max-w-full'>
            {data.taskPics?.map((taskPic: IUserProfile) => {
              if (isNil(taskPic?.status)) return '';
              return (
                <div key={taskPic.id} className='truncate'>
                  {!taskPic.status ? `(${t('account_list:status_account:inactive')})` : ''}
                  {taskPic.name}
                </div>
              );
            })}
          </div>
        );
      },
      tooltip: (_value: any, record: any) => {
        return record.taskPics?.map((taskPic: IUserProfile) => {
          if (isNil(taskPic?.status)) return '';
          return (
            <div key={taskPic.id} className='truncate'>
              {!taskPic.status ? `(${t('account_list:status_account:inactive')})` : ''}
              {taskPic.name}
            </div>
          );
        });
      }
    },
    {
      title: t('project:fields:project_id'),
      key: 'projectCode',
      width: 158,
      render: (data) => (
        <p className='truncate'>
          {data.isDraft && <span className='text-negative'>({t('task:draft')})&nbsp;</span>}
          {!data.isPublished && <span className='text-negative'>({t('task:unpublish')})&nbsp;</span>}
          <AppTooltip title={data.projectCode}>
            <Link to={VIEW_PROJECT_URL(data.projectId)}>
              <span className='underline'>{data.projectCode}</span>
            </Link>
          </AppTooltip>
        </p>
      )
    },
    {
      title: t('task:title'),
      key: 'taskName',
      width: 337,
      render: (data) => (
        <>
          {isExternalRole && data.preStatusName === STATUS_NAME.TODO ? (
            <AppTooltip title={data.taskName}>
              <span>{DataViewer.display(data.taskName)}</span>
            </AppTooltip>
          ) : (
            <AppTooltip title={data.taskName}>
              <Link to={VIEW_TASK_OF_PROJECT_URL(data.projectId, data.id)} style={{ display: 'inline-flex', alignItems: 'center', maxWidth: '100%' }}>
                <span className='underline truncate'>{data.taskName}</span>
              </Link>
            </AppTooltip>
          )}
        </>
      )
    },
    {
      title: t('task:status'),
      key: 'status',
      width: 146,
      render: (data) => renderTaskStatus(data.status, '!w-auto !inline-flex !px-[8px] !h-[24px] my-auto')
    },
    {
      title: t('task:applicant_country'),
      key: 'applicantCountryName',
      width: 140,
      render: (data) => (
        <Tooltip title={data.applicantCountryName}>
          <div className='w-fit max-w-full truncate'>{data.applicantCountryName}</div>
        </Tooltip>
      )
    },
    {
      title: t('task:applicant_name'),
      key: 'applicantName',
      width: 140,
      render: (data) => {
        const renderName = (classes?: string) =>
          data.applicantName?.split(/\r?\n/)?.map((name: string, index: number) => (
            <>
              {name && (
                <div key={`applicantName_${index}`} className={classes}>
                  {name}
                </div>
              )}
            </>
          ));
        return (
          <Tooltip title={renderName()}>
            <div className='w-fit max-w-full'>{renderName('truncate')}</div>
          </Tooltip>
        );
      }
    },
    {
      title: t('task:company_name'),
      key: 'assignmentCompanyName',
      width: 337,
      render: (data) => {
        const maxLines = Math.max(data.applicantName?.split(/\r?\n/)?.length ?? 1, data.taskPics?.length ?? 1);
        // use style because of can not use tailwind dynamic className in this case
        const style: CSSProperties =
          maxLines > 1
            ? {
                whiteSpace: 'pre-line',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: maxLines
              }
            : {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              };

        return (
          <Tooltip title={data.applicantCompanyName}>
            <div className='w-fit max-w-full overflow-hidden' style={style}>
              {DataViewer.display(data.applicantCompanyName)}
            </div>
          </Tooltip>
        );
      }
    }
  ];

  const SECONDARY_FILTER_OPTIONS = [
    {
      label: t('task:my_tasks'),
      value: TYPE_OF_TASK.ME
    },
    {
      label: t('task:all_tasks'),
      value: TYPE_OF_TASK.ALL
    },
    {
      label: t('task:assigned_tasks'),
      value: TYPE_OF_TASK.SPECIAL
    }
  ];
  const TASK_TYPES: DefaultOptionType[] = [
    { value: TASK_TYPE.OTHER, label: t('template_layout:other_category') },
    { value: TASK_TYPE.MOT, label: 'MOT' },
    { value: TASK_TYPE.TODO, label: 'TODO' },
    { value: TASK_TYPE.SUPPORT, label: t('task:type_support') }
  ];

  const SECONDARY_FILTER = isInternalRole
    ? {
        label: SECONDARY_FILTER_OPTIONS.find((option) => option.value === watchPicBy)?.label ?? SECONDARY_FILTER_OPTIONS[0]?.label,
        name: 'picBy',
        options: SECONDARY_FILTER_OPTIONS
      }
    : null;
  const totalFiltered = useMemo(
    () =>
      lodash.filter(lodash.entries(watchPrimary), ([key, value]) => {
        if (key === 'categoryId' && isExternalRole) {
          return false;
        }
        return !lodash.isNil(value) && value !== '' && (!lodash.isArray(value) || value.length > 0);
      }).length,
    [watchPrimary, isExternalRole]
  );

  const updateFilter = (data: FieldValues) => {
    setFilter({
      filter: generateFilter({
        projectPicId: isInternalRole && (data.picBy || watchPicBy) === TYPE_OF_TASK.ME ? user?.id : null,
        ...data.primary,
        categoryId: data.primary.categoryId?.length ? data.primary.categoryId : DEFAULT_FILTER_FORM.primary.categoryId
      }),
      textSearch: convertFullWidthToHalfWidth(data.search || watchSearch).trim(),
      filterTaskPics: data.primary?.picIds?.join(';'),
      assignedTasks: isInternalRole && (data.picBy || watchPicBy) === TYPE_OF_TASK.SPECIAL
    });
    filterTableRef.current?.toggle(false);
    const defaultCategoryId = data.primary?.categoryId?.length ? data.primary?.categoryId : null;
    updateFilterConfigs({
      picBy: data.picBy || watchPicBy,
      primary: {
        ...data.primary,
        categoryId: isExternalRole ? [filterConfigs?.primary?.categoryId] : defaultCategoryId
      }
    });
  };

  const resetPrimaryFilter = () => {
    reset({
      ...getValues(),
      primary: {
        ...DEFAULT_FILTER_FORM.primary,
        categoryId: isExternalRole ? [TASK_TYPE.OTHER] : DEFAULT_FILTER_FORM.primary.categoryId
      }
    });
  };

  const TagItem = ({ label, value, field }: { label: ReactNode; value?: any; field: keyof IPrimary }) => (
    <Tag
      closable
      className='bg-gray2 body-400 tag-item flex items-center m-0 gap-[5px] !h-[24px]'
      onClose={(e) => {
        e.preventDefault();
        const newPrimary = {
          ...getValues('primary'),
          [field]: !value?.length ? null : value
        };
        setValue('primary', newPrimary);
        updateFilter({
          ...getValues(),
          primary: newPrimary
        });
      }}
    >
      {label}
    </Tag>
  );

  const renderTagSection = () => {
    const tagSectionKeys: PrimaryKey[] = ['categoryId', 'countryIds', 'deadlineFrom', 'deadlineTo', 'picIds', 'statuses'];
    const keysHasValue = tagSectionKeys.filter((key) => {
      if (isExternalRole) {
        return key !== 'categoryId' && watchPrimary[key]?.length;
      }
      return watchPrimary[key]?.length;
    });
    if (!keysHasValue.length) {
      return null;
    }
    return (
      <div className='mt-[16px] flex flex-wrap gap-[8px]'>
        {keysHasValue.map((key) => {
          if (key === 'categoryId') {
            const categoryIds = (watchPrimary[key] ?? []) as string[];
            return categoryIds.map((category) => (
              <TagItem
                key={`${key}_${category}`}
                label={
                  category === TASK_TYPE.OTHER
                    ? t('template_layout:other_category')
                    : category === TASK_TYPE.SUPPORT
                      ? t('task:type_support')
                      : category
                }
                value={categoryIds.filter((item) => item !== category)}
                field={key}
              />
            ));
          }
          if (['countryIds', 'picIds', 'statuses'].includes(key)) {
            const tagIds = (watchPrimary[key] ?? []) as string[];
            return tagIds.map((id) => {
              const picName = picListInfo?.find((user) => user?.id === id)?.label;
              const countryName = countryOptions?.find((option) => option.value === id)?.label;
              const status = statuses?.find((item) => item.id === id)?.name;
              const label: Record<string, ReactNode> = {
                countryIds: countryName,
                picIds: picName,
                statuses: status
              };
              return <TagItem key={`${key}_${id}`} label={label[key]} value={tagIds.filter((item) => item !== id)} field={key} />;
            });
          }
          const value = watchPrimary[key] as any;
          const label: Record<string, ReactNode> = {
            categoryId: TASK_TYPES.find((type) => type.value === value)?.label,
            deadlineFrom: formatDateTime(value),
            deadlineTo: formatDateTime(value)
          };
          return <TagItem key={key} label={label[key]} field={key} />;
        })}
        <Button
          size='small'
          className='tag-btn__clear-all !h-[24px]'
          onClick={(e) => {
            e.preventDefault();
            resetPrimaryFilter();
            updateFilter({
              ...getValues(),
              primary: {
                ...DEFAULT_FILTER_FORM.primary,
                categoryId: isExternalRole ? [TASK_TYPE.OTHER] : DEFAULT_FILTER_FORM.primary.categoryId
              }
            });
          }}
        >
          {t('button:clear_filter')}
        </Button>
      </div>
    );
  };

  const customParamSort = (sortField: React.Key | null | undefined, sortOrder: SortOrder | null, defaultSort?: IDefaultSort<IObject>[]) => {
    const sorts = defaultSort ? [...defaultSort] : [];
    if (sortField && !sorts.some((sort) => sort.field === sortField)) {
      sorts.unshift({ field: String(sortField), order: 'ascend' });
    }
    return sorts
      .map((sort) => {
        // Order sort of createdDate is always desc
        const sortDirection = sort.field === 'createdDate' ? QUERY_SORT.DESC : ANTD_TO_QUERY_SORT[sortOrder ?? sort.order];
        return `${sort.field}=${sortDirection}`;
      })
      .join(',');
  };

  return (
    <div className='space-y-[16px]'>
      <h3 className='text-2xl m-0'>{t('all_task_project:title_page')}</h3>
      <TableFetch
        tableHeader={
          <FormProvider {...methods}>
            <div className='mb-4'>
              <FormBaseFilter
                searchBox={{ placeholder: t('task:search_placeholder') ?? '' }}
                primaryAction={{
                  label: t('button:filter'),
                  name: 'primary',
                  popoverContent: (
                    <PrimaryFilter
                      statuses={statuses}
                      taskTypes={TASK_TYPES}
                      picListInfo={picListInfo}
                      setPicListInfo={setPicListInfo}
                      onSubmit={(data) => {
                        setValue('primary', data.primary);
                        updateFilter(data);
                      }}
                      onReset={resetPrimaryFilter}
                    />
                  ),
                  totalFiltered,
                  refControl: filterTableRef
                }}
                secondaryAction={SECONDARY_FILTER}
                tagSection={renderTagSection()}
                onSubmit={updateFilter}
              />
            </div>
          </FormProvider>
        }
        apiEndpoint={API.GET_TASKS_OF_ALL_PROJECTS}
        rowSelection={null}
        defaultSort={[
          { field: 'deadLine', order: 'ascend' },
          { field: 'createdDate', order: 'descend' }
        ]}
        customParamSort={customParamSort}
        apiMethod='POST'
        columns={COLUMNS}
        filterDefault={filter}
        scrollTable={{ x: 0, maxHeight: '100vh - 248px' }}
      />
    </div>
  );
};
